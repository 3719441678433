.footer-container {
    padding: 0px 120px;
    background: linear-gradient(120deg, rgba(18,42,66,1) 0%, rgba(7,45,84,1) 100%);
}

.footer-col {
    height: 348px;
    font-size: 12px !important;
}

.footer-span {
    font-size: 12px;
    color: #fff;
    line-height: 12px;
}

.center-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.footer-header {
    width: 90%;
    font-size: 18px;
    line-height: 18px;
}

#bankid {
    max-height: 148px;
}

#scf-logo {
    height: 54px;
}




@media screen and (max-width: 900px) {
    .footer-col {
        margin-bottom: 20px;
        margin-top: 20px;
        height: 150px;
    }
    .footer-container {
        padding: 0px 25px;
        height: auto;
        padding-bottom: 50px;
    }
}