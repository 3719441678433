.customer-container{

    border: 1px solid #0B2254;
    height: 250px;
    margin: 20px 0px;
    padding: 25px 35px;
    margin-bottom: 100px;
}

.cc-left {
    margin-left: 12px;
}
.cc-right {
    margin-right: 12px;
}

h5 {
    color: #32B4D2;
    font-weight: 700;
}

span {
    color: #0B2254;
    font-size: 16px;
}

h1 {
    color: #0B2254;
    font-weight: 700;
}

.arrow {
    border: 1px solid #0B2254;
    position: absolute;
    bottom: 25px;
    right: 35px;
}

@media screen and (max-width: 900px) {
    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 1.5rem;
    }
    .arrow {
        right: 10px;
        bottom: 10px;
    }
    
    .customer-container{ 
        margin: 10px 10px;
        height: 265px;
        margin-bottom: 100px;
    }

    .cc-left {
        margin-left: 0px;
    }
    .cc-right {
        margin-right: 0px;
    }
}